.notSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pageSetting {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #191919;
}

html:focus-within {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

span#page-msg-develop {
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #ff9900;
}
