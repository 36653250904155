div#history-section-mid {
  position: relative;
  height: 100%;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div#v-history-section-mid {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/*
*
*
*
MOBILE
*
*
*
*/

/*
*
*
*
*
SECTION TOP
*
*
*
*/

div#v-history-section-mid-top {
  position: relative;
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div#v-history-section-mid-top-box {
  position: relative;
  width: 95%;
  height: 97%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: flex-start;
  overflow-y: scroll;
}

/*
*
*
*
*
SECTION BOT
*
*
*
*/

div#v-history-section-mid-bot {
  position: relative;
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

div#v-history-section-mid-bot-box {
  position: relative;
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #303030;
  margin: 0 auto;
  justify-content: center;
}

div#v-history-section-mid-bot-text {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
}

div#v-history-section-mid-bot-text span {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  color: #999;
  text-indent: 15px;
}

/*
*
*
*
DESKTOP
*
*
*
*/

/*
*
*
*
*
SECTION TOP
*
*
*
*/

div#history-section-mid-top {
  position: relative;
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div#history-section-mid-top-box {
  position: relative;
  width: 95%;
  height: 97%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
}

/*
*
*
*
*
SECTION BOT
*
*
*
*/

div#history-section-mid-bot {
  position: relative;
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

div#history-section-mid-bot-box {
  position: relative;
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #303030;
  margin: 0 auto;
  justify-content: center;
}

div#history-section-mid-bot-text {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
}

div#history-section-mid-bot-text span {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  color: #999;
  text-indent: 15px;
}
