div#contact-section-mid {
  position: relative;
  height: 100%;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div#v-contact-section-mid {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/*
*
*
*
MOBILE
*
*
*/

/*
*
*
*
*
SECTION TOP
*
*
*
*/

div#v-contact-section-mid-top {
  position: relative;
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div#v-contact-section-mid-top-box {
  position: relative;
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: flex-start;
  overflow: hidden;
}

div#v-contact-section-mid-top-box-line1 {
  position: relative;
  width: 100%;
  height: 17%;
  min-height: 17%;
  max-height: 17%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#v-contact-section-mid-top-box-line1 span {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

div#v-contact-section-mid-top-box-line2 {
  position: relative;
  width: 100%;
  height: 83%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
}

div#v-contact-section-mid-top-box-card {
  position: relative;
  width: 100%;
  height: min-content;
  background-color: #303030;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: width 500ms;
  transition-timing-function: ease;
  box-shadow: 0px 0px 10px 2px #202020;
}

div#v-contact-card-item {
  position: relative;
  width: 85%;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px auto 5px auto;
}

span#v-contact-card-item-title {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

span#v-contact-card-item-value {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 500;
  color: #ff9900;
}

/*
*
*
*
*
SECTION MID
*
*
*
*/

div#v-contact-section-mid-mid {
  position: relative;
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#v-contact-section-mid-mid-box {
  position: relative;
  width: 95%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

div#v-contact-section-mid-mid-box-line1 {
  position: relative;
  width: 100%;
  height: 12%;
  min-height: 12%;
  max-height: 12%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#v-contact-section-mid-mid-box-line1 span {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

div#v-contact-section-mid-mid-box-line2 {
  position: relative;
  width: 100%;
  height: 88%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #303030;
  box-shadow: 0px 0px 10px 2px #202020;
}

/*
*
*
*/

div#v-contact-form-name {
  position: relative;
  width: 95%;
  height: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

input#v-contact-form-name-input {
  position: relative;
  width: 97%;
  height: 100%;
  margin: 0 auto;
  background-color: #202020;
  border: none;
  font-size: 13px;
  color: #fff;
  font-family: 'Poppins';
  padding: 11px;
}

/*
*
*
*/

div#v-contact-form-email {
  position: relative;
  width: 95%;
  height: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

input#v-contact-form-email-input {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #202020;
  border: none;
  font-size: 13px;
  color: #fff;
  font-family: 'Poppins';
  padding: 11px;
}

/*
*
*
*/

div#v-contact-form-message {
  position: relative;
  width: 95%;
  height: 100px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

textarea#v-contact-form-message-input {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #202020;
  border: none;
  font-size: 13px;
  color: #fff;
  font-family: 'Poppins';
  padding: 11px;
  resize: none;
}

/*
*
*
*/

div#v-contact-form-button {
  position: relative;
  width: 95%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

div#v-contact-form-button-button {
  position: relative;
  width: 180px;
  height: 100%;
  background-color: #ff9900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: background-color 500ms;
}

span#v-contact-form-button-button-text {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  color: #303030;
}

div#v-contact-form-res-status {
  position: relative;
  width: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#v-contact-form-res-status span {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  color: #ff9900;
  margin-left: 10px;
}

/*
*
*
*
*
SECTION BOT
*
*
*
*/

div#v-contact-section-mid-bot {
  position: relative;
  width: 100%;
  height: 5%;
  min-height: 5%;
  max-height: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

div#v-contact-section-mid-bot-box {
  position: relative;
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #303030;
  margin: 0 auto;
  justify-content: center;
}

div#v-contact-section-mid-bot-text {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
}

div#v-contact-section-mid-bot-text span {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  color: #999;
  text-indent: 15px;
}

/*
*
*
*
DESKTOP
*
*
*/

/*
*
*
*
*
SECTION TOP
*
*
*
*/

div#contact-section-mid-top {
  position: relative;
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div#contact-section-mid-top-box {
  position: relative;
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: flex-start;
  overflow: hidden;
}

div#contact-section-mid-top-box-line1 {
  position: relative;
  width: 100%;
  height: 30%;
  min-height: 30%;
  max-height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#contact-section-mid-top-box-line1 span {
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

div#contact-section-mid-top-box-line2 {
  position: relative;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div#contact-section-mid-top-box-card {
  position: relative;
  width: 31%;
  height: min-content;
  background-color: #303030;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: width 500ms;
  transition-timing-function: ease;
  box-shadow: 0px 0px 10px 2px #202020;
}

div#contact-card-item {
  position: relative;
  width: 85%;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px auto 5px auto;
}

span#contact-card-item-title {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

span#contact-card-item-value {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 500;
  color: #ff9900;
}

/*
*
*
*
*
SECTION MID
*
*
*
*/

div#contact-section-mid-mid {
  position: relative;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#contact-section-mid-mid-box {
  position: relative;
  width: 95%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

div#contact-section-mid-mid-box-line1 {
  position: relative;
  width: 100%;
  height: 15%;
  min-height: 15%;
  max-height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#contact-section-mid-mid-box-line1 span {
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

div#contact-section-mid-mid-box-line2 {
  position: relative;
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #303030;
  box-shadow: 0px 0px 10px 2px #202020;
}

/*
*
*
*/

div#contact-form-name {
  position: relative;
  width: 95%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

input#contact-form-name-input {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #202020;
  border: none;
  font-size: 14px;
  color: #fff;
  font-family: 'Poppins';
  padding: 11px;
}

*:focus {
  outline: none;
}

/*
*
*
*/

div#contact-form-email {
  position: relative;
  width: 95%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

input#contact-form-email-input {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #202020;
  border: none;
  font-size: 14px;
  color: #fff;
  font-family: 'Poppins';
  padding: 11px;
}

/*
*
*
*/

div#contact-form-message {
  position: relative;
  width: 95%;
  height: 150px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

textarea#contact-form-message-input {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #202020;
  border: none;
  font-size: 14px;
  color: #fff;
  font-family: 'Poppins';
  padding: 11px;
  resize: none;
}

/*
*
*
*/

div#contact-form-button {
  position: relative;
  width: 95%;
  height: 55px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

div#contact-form-button-button {
  position: relative;
  width: 180px;
  height: 100%;
  background-color: #ff9900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: background-color 500ms;
}

span#contact-form-button-button-text {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  color: #303030;
}

div#contact-form-res-status {
  position: relative;
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#contact-form-res-status span {
  text-indent: 10px;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  color: #ff9900;
}

/*
*
*
*
*
SECTION BOT
*
*
*
*/

div#contact-section-mid-bot {
  position: relative;
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

div#contact-section-mid-bot-box {
  position: relative;
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #303030;
  margin: 0 auto;
  justify-content: center;
}

div#contact-section-mid-bot-text {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
}

div#contact-section-mid-bot-text span {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  color: #999;
  text-indent: 15px;
}
