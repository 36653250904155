div#base-sections {
  position: relative;
  width: 80%;
  height: 96%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div#base-sections-v {
  position: relative;
  width: 96%;
  height: 98%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#v-button-left {
  position: absolute;
  z-index: 50;
  left: 10px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 8%;
}

div#v-button-left svg {
  color: #ff9900;
  margin: 0 auto;
  position: relative;
  font-size: 20px;
}

div#v-button-right {
  position: absolute;
  z-index: 50;
  right: 15px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 8%;
}

div#v-button-right svg {
  color: #ff9900;
  margin: 0 auto;
  position: relative;
  font-size: 31px;
}

div#v-profile {
  position: relative;
  margin: 0 auto;
  height: 100%;
}

div#v-menu {
  position: absolute;
  height: 100%;
  right: 0px;
}
