div#transition-section-mid {
  position: relative;
  height: 100%;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#transition-section-mid-mid {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#transition-section-mid-bar {
  position: relative;
  width: 200px;
  height: 5px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  background-color: #444;
}

div#transition-section-mid-bar-color {
  position: relative;
  height: 5px;
  background-color: #ff9900;
}

div#transition-section-mid-bar-title {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

span#transition-section-mid-bar-title-style {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 13px;
  color: #ff9900;
}
