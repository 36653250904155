div#base-section-right {
  position: relative;
  min-width: 80px;
  width: 80px;
  max-width: 80px;
  height: 100%;
  background-color: #202020;
  transition:
    width 500ms,
    max-width 500ms,
    min-width 500ms;
  transition-timing-function: ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0px 0px 6px 0px #191919;
  z-index: 10001;
}

div#base-section-right-top {
  position: relative;
  width: 100%;
  height: 8%;
  background-color: #292929;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

svg#menu-open {
  margin: 0 auto;
  font-size: 24px;
  color: #ff9900;
  cursor: pointer;
  transition:
    color 500ms,
    font-size 500ms;
  transition-timing-function: ease;
}

svg#menu-open:hover {
  color: #fff;
}

div#base-section-right-mid {
  position: relative;
  width: 100%;
  height: 84%;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#base-section-right-mid-items {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div#base-section-right-mid-item {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
}

div#base-section-right-mid-item div {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div#base-section-right-mid-item div span {
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  color: #999;
  margin: 5px 0px 5px 20px;
  opacity: 1;
  cursor: pointer;
  transition: color 500ms;
  transition-timing-function: ease;
}

div#base-section-right-mid-item div span:hover {
  color: #fff;
}

.base-section-right-mid-item-icon-1 {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #999;
  transition: opacity 100ms;
  transition-timing-function: linear;
  cursor: pointer;
}

.base-section-right-mid-item-icon-2 {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #999;
  transition: opacity 100ms;
  transition-timing-function: linear;
  cursor: pointer;
}

div#base-section-right-bot {
  position: relative;
  width: 100%;
  height: 8%;
  background-color: #292929;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div#base-section-right-bot-items {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

span#language {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 13px;
  color: #000;
  background-color: #ff9900;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 4px 0px 4px;
  transition: display 500ms;
  transition-timing-function: ease;
}

div#base-list-projects {
  position: relative;
  width: 100%;
  max-height: 150px;
  height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: #202020;
}

div#base-list-projects-item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 2px;
}

p#base-list-projects-item-title {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  color: #999;
  margin-left: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  transition:
    color 200ms,
    margin 200ms;
  transition-timing-function: ease;
  padding: 2px 10px 2px 5px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

p#base-list-projects-item-title:hover {
  color: #fff;
  margin-left: 40px;
}
